import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';

// const RESTAPI = 'http://portal.wimark.tk:13260';

export default {
  getSessions(ctx, props = {}) {
    // ctx.$store.commit('toggleLoadingPortalPages');
    ctx.$store.commit('loadingPortalSessionsRequestBegin');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          accounts: [ctx.account.id],
            location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {params: {
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/session`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          ctx.$store.commit('setPortalSessionsList', response.data.data);
          // this.getPagesLocations(ctx, ctx.$store.state.portalPagesList);
          ctx.totalResultPortalSessions = response.data.total;
          if (ctx.$store.state.portalSessionsList.length >= response.data.total) {
            ctx.canLoadMorePortalSessions = false;
          } else {
            ctx.canLoadMorePortalSessions = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        // ctx.$store.commit('toggleLoadingPortalSessions');
        ctx.$store.commit('loadingPortalSessionsRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('loadingPortalSessionsRequestEnd');
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalSessions');
      }
    );
  },
  updatePage(ctx, page) {
    ctx.$store.commit('toggleMutePortalPageEdit');
    const { base_location } = page;
    delete page.base_location;
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/session`, {
        request: [page],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            // VueNotifications.success({ message: 'Page Updated Successfully' });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
            VueNotifications.success({ message: ctx.$t('pageprofile.pageUpdatedSuccessfully') });
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalPageEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalPageEdit');
        }
      );
  },
  deletePage(ctx, profile) {
    ctx.deletingPageModal = false;
    ctx.$store.commit('toggleMutePortalPageEdit');
    Vue.axios
      .delete(`${RESTAPI || ''}/api/portal/admin/webpage`, {
        params: {
          ids: [profile.id]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: 'Page Deleted Successfully' });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalPageEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalPageEdit');
        }
      );
  },
  getSessionsWithQuery(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalSessions');
    const config = props.query ?
      {
        params: {
          profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          q: ctx.formattedQ,
          location_id: ctx.filters.location?.data?.id || [],
          location: ctx.filters.location?.data?.id || [],
          with_childs: ctx.filters.location?.with_childs || false,
          start: moment(ctx.filters.date.startDate).unix(),
          stop: moment(ctx.filters.date.endDate).unix()
        }
      } :
      {};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/session`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          ctx.$store.commit('pushPortalSessionsList', response.data.data);
          // this.getPagesLocations(ctx, ctx.$store.state.portalPagesList);
          ctx.totalResultPortalSessions = response.data.total;
          if (ctx.$store.state.portalSessionsList.length >= response.data.total) {
            ctx.canLoadMorePortalSessions = false;
          } else {
            ctx.canLoadMorePortalSessions = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalSessions');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalSessions');
      }
    );
  },

  // getSessionsWithQueryV2(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingPortalSessions');
  //   const config = props.query ?
  //     {
  //       params: {
  //         profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
  //         q: ctx.formattedQ,
  //         location_id: ctx.filters.location?.data?.id || [],
  //         with_childs: ctx.filters.location?.with_childs || false
  //         // start: moment(ctx.filters.date.startDate).unix(),
  //         // stop: moment(ctx.filters.date.endDate).unix()
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/session`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalPagesList', response.data.data);
  //         ctx.$store.commit('pushPortalSessionsList', response.data.data);
  //         // this.getPagesLocations(ctx, ctx.$store.state.portalPagesList);
  //         ctx.totalResultPortalSessions = response.data.total;
  //         if (ctx.$store.state.portalSessionsList.length >= response.data.total) {
  //           ctx.canLoadMorePortalSessions = false;
  //         } else {
  //           ctx.canLoadMorePortalSessions = true;
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalSessions');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalSessions');
  //     }
  //   );
  // },

  getSessionsWithQueryV2(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalSessions');
    const config = props.query ?
      {
        params: {
          profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          q: ctx.formattedQ,
          location_id: ctx.filters.location?.data?.id || [],
          with_childs: ctx.filters.location?.with_childs || false,
          location: ctx.filters.location?.data?.id || [],
          // start: moment(ctx.filters.date.startDate).unix(),
          // stop: moment(ctx.filters.date.endDate).unix()
        }
      } :
      {};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/session`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          ctx.$store.commit('pushPortalSessionsList', response.data.data);
          // this.getPagesLocations(ctx, ctx.$store.state.portalPagesList);
          ctx.totalResultPortalSessions = response.data.total;
          if (ctx.$store.state.portalSessionsList.length >= response.data.total) {
            ctx.canLoadMorePortalSessions = false;
          } else {
            ctx.canLoadMorePortalSessions = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalSessions');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalSessions');
      }
    );
  },

  updateSession(ctx, session) {
    ctx.$store.commit('portalSessionUpdateRequestExecutingBegin');
    const updatedSession = JSON.parse(JSON.stringify(session));
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/session`, {
        request: [updatedSession]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            // console.log('success')
            // ctx.clearQuery();
            // this.getSessionsWithQuery(ctx, { query: true });
            if (
              response.data.hasOwnProperty('data') &&
              Array.isArray(response.data.data) &&
              response.data.data.length !== 0
            ) {
              // если успешно обновили сессию, и в ответ на PUT прилетел обновленый объект
              // обновляем его в stor'e чтобы он сразу обновился в таблице
              ctx.$store.commit(
                'portalSessionUpdateSessionObjectInStore',
                JSON.parse(JSON.stringify(response.data.data[0]))
              );
            }
            ctx.$store.commit('portalSessionUpdateRequestExecutingEnd');
          } else {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('portalSessionUpdateRequestExecutingEnd');
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          ctx.$store.commit('portalSessionUpdateRequestExecutingEnd');
        }
      );
  }
};
